<i18n lang="yaml">
pt:
  options: 'Opções'
en:
  options: 'Options'
</i18n>

<template>
  <v-list
    v-model:opened="opened"
    class="draggable-list-v2"
    :class="{ 'draggable-list-v2--deemphasize-inactive-items': deemphasizeInactiveItems && Boolean(activeItemId) }"
    :style="cssProps"
    density="compact"
  >
    <v-divider
      v-if="divide"
      class="my-2"
    />

    <v-list-group
      v-if="nested"
      ref="listGroup"
      value="group"
      color="text"
      :class="{ 'draggable-list-v2--gray': grayMode }"
      :append-icon="null"
      density="compact"
    >
      <template #activator="{ isOpen }">
        <ListGroupActivator
          class="draggable-list-v2__item"
          :icon="icon"
          :title="title"
          :actions="actions"
          :left="left"
          :draggable="draggable && !disabled"
          :hide-options-menu="hideOptionsMenu"
          :group-toggled="isOpen"
          :toggle-through-chevron="toggleThroughChevron"
          :active="activeItemId === itemId"
          @toggle-group="handleToggleGroup"
          @activator-clicked="activatorClicked(itemId)"
          @menu-activator-click="$emit('menuActivatorClick', $event)"
        >
          <!-- Pass on all scoped slots -->
          <template
            v-for="slot in Object.keys($slots)"
            #[slot]="scope"
          >
            <slot
              v-bind="scope"
              :name="slot"
            />
          </template>
        </ListGroupActivator>
      </template>

      <Vuedraggable
        v-model="internalValue"
        class="draggable-list-v2__items"
        item-key="id"
        :group="{ name: uuid }"
        :animation="200"
        v-bind="{ disabled }"
        ghost-class="draggable-list-v2__item--ghost"
      >
        <template #item="{ element: item, index }">
          <DraggableListV2
            v-if="item && item.items"
            :key="item.id"
            v-model="item.items"
            :item-id="item.id"
            :title="item.title"
            :icon="item.icon"
            :uuid="uuid"
            :actions="item.actions"
            :selected-id="selectedId"
            :dense="dense"
            :exact-path="exactPath"
            :persistent-drag-icon="persistentDragIcon"
            :left="left"
            :draggable="item.draggable"
            :gray-mode="item.grayMode"
            :divide="item.divide"
            :collapsed="collapsed"
            :disabled="disabled"
            :nesting-level="nestingLevel + 1"
            :hide-options-menu="hideOptionsMenu"
            :active-item-id="activeItemId"
            :toggle-through-chevron="toggleThroughChevron"
            :deemphasize-inactive-items="deemphasizeInactiveItems"
            nested
            debounce-disabled
            @update:model-value="inputChange"
            @item-click="itemClicked($event)"
            @menu-activator-click="$emit('menuActivatorClick')"
          >
            <!-- Pass on all scoped slots -->
            <template
              v-for="slot in Object.keys($slots)"
              #[slot]="scope"
            >
              <slot
                v-bind="scope"
                :name="slot"
              />
            </template>
          </DraggableListV2>

          <ListItem
            v-else-if="item && !item.hide"
            :key="item.id || index"
            :exact-path="exactPath"
            :index="index"
            :item="item"
            :left="left"
            :draggable="draggable && !disabled"
            :hide-options-menu="hideOptionsMenu"
            :active="activeItemId === item.id"
            class="draggable-list-v2__item draggable-list-v2__item--nested"
            @click="itemClicked(item.id || index)"
            @menu-activator-click="$emit('menuActivatorClick')"
          >
            <!-- Pass on all scoped slots -->
            <template
              v-for="slot in Object.keys($slots)"
              #[slot]="scope"
            >
              <slot
                :name="slot"
                v-bind="scope"
              />
            </template>
          </ListItem>
        </template>
      </Vuedraggable>
    </v-list-group>

    <div
      v-else
      class="draggable-list-v2__items"
      :class="listCustomClass"
      :max-height="listMaxHeight"
    >
      <slot name="list-header" />

      <Vuedraggable
        v-model="internalValue"
        :group="{ name: uuid }"
        item-key="id"
        :animation="200"
        v-bind="{ disabled }"
        ghost-class="draggable-list-v2__item--ghost"
      >
        <template #item="{ element: item, index }">
          <DraggableListV2
            v-if="item && item.items"
            :key="item.id"
            v-model="item.items"
            :item-id="item.id"
            :title="item.title"
            :icon="item.icon"
            :uuid="uuid"
            :actions="item.actions"
            :selected-id="selectedId"
            :exact-path="exactPath"
            :persistent-drag-icon="persistentDragIcon"
            :dense="dense"
            :left="left"
            :draggable="item.draggable"
            :gray-mode="item.grayMode"
            :divide="item.divide"
            :collapsed="collapsed"
            :disabled="disabled"
            :hide-options-menu="hideOptionsMenu"
            :active-item-id="activeItemId"
            :toggle-through-chevron="toggleThroughChevron"
            :deemphasize-inactive-items="deemphasizeInactiveItems"
            debounce-disabled
            nested
            @item-click="itemClicked($event)"
            @menu-activator-click="$emit('menuActivatorClick')"
            @update:model-value="inputChange"
          >
            <!-- Pass on all scoped slots -->
            <template
              v-for="slot in Object.keys($slots)"
              #[slot]="scope"
            >
              <slot
                :name="slot"
                v-bind="scope"
              />
            </template>
          </DraggableListV2>

          <ListItem
            v-else-if="item && !item.hide"
            :key="item.id || index"
            :exact-path="exactPath"
            :index="index"
            :item="item"
            :item-title-typography="item.titleTypography || 'text-body-2'"
            :left="left"
            :draggable="draggable && !disabled"
            class="draggable-list-v2__item"
            :hide-options-menu="hideOptionsMenu"
            :active="activeItemId === item.id"
            @click="itemClicked(item.id || index)"
            @menu-activator-click="$emit('menuActivatorClick')"
          >
            <!-- Pass on all scoped slots -->
            <template
              v-for="slot in Object.keys($slots)"
              #[slot]="scope"
            >
              <slot
                :name="slot"
                v-bind="scope"
              />
            </template>
          </ListItem>
        </template>
      </Vuedraggable>
    </div>
  </v-list>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import Vuedraggable from 'vuedraggable';
import ListItem from '~/components/list/ListItem';
import ListGroupActivator from '~/components/list/ListGroupActivator';
import { isIdPresentInRecursiveList } from '~/assets/javascript/utils';
import debounce from 'lodash/debounce';

export default {
  name: 'DraggableListV2',
  components: {
    Vuedraggable,
    ListItem,
    ListGroupActivator,
  },
  props: {
    selectedId: {
      type: [String, null],
      default: null,
    },
    nested: {
      type: Boolean,
      default: false,
    },
    nestingLevel: {
      type: Number,
      default: 0,
    },
    modelValue: {
      type: Array,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    actions: {
      type: Array,
      default: null,
    },
    hideOptionsMenu: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
    uuid: {
      type: String,
      default: () => uuidv4(),
    },
    dense: {
      type: Boolean,
      default: false,
    },
    persistentDragIcon: {
      type: Boolean,
      default: false,
    },
    listCustomClass: {
      type: String,
      default: null,
    },
    listMaxHeight: {
      type: [String, Number],
      default: undefined,
    },
    // eslint-disable-next-line vue/no-unused-properties
    listOutlined: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    // eslint-disable-next-line vue/no-unused-properties
    ghostClass: {
      type: String,
      default: undefined,
    },
    exactPath: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: undefined,
    },
    draggable: {
      type: Boolean,
      default: true,
    },
    grayMode: {
      type: Boolean,
      default: false,
    },
    divide: {
      type: Boolean,
      default: false,
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
    itemsGap: {
      type: String,
      default: '1px',
    },
    groupsGap: {
      type: String,
      default: 'var(--z-s2)',
    },
    activeItemId: {
      type: String,
      default: null,
    },
    toggleThroughChevron: {
      type: Boolean,
      default: false,
    },
    itemId: {
      type: String,
      default: null,
    },
    deemphasizeInactiveItems: {
      type: Boolean,
      default: false,
    },
    /**
     * The DraggableListV2 is recursive, so you can have multiple DraggableListV2 inside another DraggableListV2.
     * When you move something from one DraggableListV2 to another DraggableListV2,
     * it emits 2 update events: one for the item that left one DraggableListV2 and
     * another for the item that entered the other DraggableListV2.
     * By placing the debounce only on the root DraggableListV2,
     * We can capture all these events and emit only the last one reflecting the final state of the component.
     * This makes the component more performant because now
     * we only need to handle one change instead of two very quick ones.
     */
    debounceDisabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'itemClick', 'menuActivatorClick'],
  data() {
    const internalValue = _cloneDeep(this.modelValue);

    return {
      opened: isIdPresentInRecursiveList({ items: internalValue }, this.selectedId) ? ['group'] : [],
      emitInputChangeWithDebounce: debounce(this.emitInputChange, 50),
      internalValue,
    };
  },
  computed: {
    cssProps() {
      return {
        '--draggable-list-v2-nesting-level': this.nestingLevel,
        '--draggable-list-v2-items-gap': this.itemsGap,
        '--draggable-list-v2-groups-gap': this.groupsGap,
      };
    },
  },
  watch: {
    modelValue: {
      deep: true,
      handler(value) {
        if (_isEqual(this.internalValue, value)) return;

        this.internalValue = _cloneDeep(value);
      },
    },
    internalValue: {
      deep: true,
      handler(value) {
        if (_isEqual(value, this.modelValue)) return;

        this.inputChange();
      },
    },
  },
  methods: {
    inputChange() {
      if (this.debounceDisabled) {
        this.emitInputChange();
      } else {
        this.emitInputChangeWithDebounce();
      }
    },
    emitInputChange() {
      this.$emit('update:modelValue', this.internalValue);
    },
    itemClicked(id) {
      this.$emit('itemClick', id);
    },
    activatorClicked(id) {
      if (!this.toggleThroughChevron) {
        this.handleToggleGroup();
      }

      this.$emit('itemClick', id);
    },
    handleToggleGroup() {
      if (this.opened.includes('group')) {
        this.opened = [];
      } else {
        this.opened = ['group'];
      }
    },
  },
};
</script>

<style lang="scss">
.draggable-list-v2 {
  --draggable-list-v2-items-indentation: var(--z-s4);
  --draggable-list-v2-group-background-color: transparent;
  --draggable-list-v2-group-background-color-highlight: var(--z-color-subtle-shade);
  --draggable-list-v2-item-opacity: 1;
  --draggable-list-v2-inactive-item-opacity: 0.6;
  --draggable-list-v2-inactive-group-activator-opacity: 0.4;  // Must be less than above because the bolder font makes it more highlighted

  background-color: transparent !important;
  padding: 0 !important;
  overflow: visible !important;

  > .v-list {
    padding: 0;
  }

  .v-list-item {
    overflow: hidden; // To avoid item actions trigger an horizontal overflow when transitioning
  }

  // Spacing between child groups for hierarchy
  & .draggable-list-v2 {
      padding-block: calc(var(--draggable-list-v2-groups-gap) / 2);

    &:first-child, & + & {
      padding-top: 0; // Keep first child of group (either if item or group) right next to its header
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  + .draggable-list-v2__item {
    margin-top: calc(var(--draggable-list-v2-groups-gap) / 2) !important;
  }
}

.draggable-list-v2__item--ghost {
  opacity: 0.5 !important;
  outline: 1px dashed rgba(0, 0, 0, 0.5) !important;
  outline-offset: -2px !important;
  border-radius: 8px !important;

  &::after {
    background-color: var(--draggable-list-v2-group-background-color-highlight) !important;
  }

  .v-ripple__animation--in {
    opacity: 0 !important;
  }
}

.draggable-list-v2__item {
  --left-spacing: calc(
      var(--draggable-list-v2-items-indentation)
    * (var(--draggable-list-v2-nesting-level) + 1)
  );

  margin-left: calc(var(--left-spacing) * -1) !important;
  padding-inline-end: var(--z-s2) !important;
  max-width: 100vw !important;
  width: calc(100% + var(--left-spacing)) !important;

  &#{&} {
    padding-inline-start: var(--left-spacing) !important;
  }

  .v-list-group__items {
    overflow: unset;
  }

  &:not(:first-child) {
    margin-top: var(--draggable-list-v2-items-gap);
  }

  // Spacing between sibling items from groups for hierarchy
  + .draggable-list-v2 {
    margin-top: calc(var(--draggable-list-v2-groups-gap) / 2);
  }
}

.draggable-list-v2__item--nested {
  --left-spacing: calc(
      var(--draggable-list-v2-items-indentation)
    * (var(--draggable-list-v2-nesting-level) + 2)
  );
}

.draggable-list-v2__items {
  padding-right: 0;
  padding-left: var(--draggable-list-v2-items-indentation);
  margin-top: var(--draggable-list-v2-items-gap);
  min-height: 32px;

  &:empty {
    border-radius: 4px;
    border: 2px dotted #E0E0E0;
    margin-left: 8px;
  }
}

.draggable-list-v2--gray {
  .v-list-item__title {
    color: gray !important;
  }
}

/**
  * Deemphasize inactive items, but not for the first level of active groups
  */
.draggable-list-v2--deemphasize-inactive-items {
  .list-item:not(:is(:hover, :focus-visible, .list-item--active)) .list-item__content {
    opacity: var(--draggable-list-v2-inactive-item-opacity);
  }

  .list-group-activator:not(:is(:hover, :focus-visible, .list-group-activator--active)) .list-group-activator__content {
    opacity: var(--draggable-list-v2-inactive-group-activator-opacity);
  }

  .draggable-list-v2__item--group:has(> .v-list-group__header > .list-group-activator--active) {
    --draggable-list-v2-inactive-item-opacity: 1 !important;
    --draggable-list-v2-inactive-group-activator-opacity: 1 !important;

    .draggable-list-v2__item--group .draggable-list-v2__items {
      --draggable-list-v2-inactive-item-opacity: 0.6 !important;
      --draggable-list-v2-inactive-group-activator-opacity: 0.4 !important;
    }
  }
}
</style>
