<template>
  <v-list-item
    v-test-id="'list-group-activator-item'"
    class="list-group-activator"
    role="listitem"
    tabindex="-1"
    :class="classes"
    :aria-label="title"
    density="compact"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    @click.prevent.stop="$emit('activatorClicked', $event)"
  >
    <deck-icon
      v-if="draggable"
      v-test-class="'list-group-activator-drag-icon'"
      class="list-group-activator__drag-icon"
      name="grip-dots-vertical"
    />

    <template #prepend>
      <deck-button
        v-if="toggleThroughChevron"
        :text="groupToggled ? $t('global.collapse') : $t('global.expand')"
        :icon="groupToggled ? 'chevron-down' : 'chevron-right'"
        kind="ghost"
        color="controls"
        size="small"
        class="list-group-activator__chevron"
        is-ready
        :class="{ 'list-group-activator__chevron--toggled': groupToggled }"
        @click.prevent.stop.capture="$emit('toggleGroup')"
      />
      <deck-icon
        v-else
        class="list-group-activator__chevron"
        :class="{ 'list-group-activator__chevron--toggled': groupToggled }"
        :name="groupToggled ? 'chevron-down' : 'chevron-right'"
        size="small"
        fixed-width
      />
      <deck-icon
        v-if="Boolean(icon)"
        class="list-group-activator__content-icon"
        color="var(--z-color-label)"
        size="small"
        fixed-width
        :name="icon"
      />
    </template>

    <v-list-item-title
      :title="title"
      class="list-group-activator__content-title text-subtitle-2"
    >
      {{ title }}
    </v-list-item-title>

    <template #append>
      <!-- This is the menu of a group -->
      <OptionsMenu
        v-if="!hideOptionsMenu && actions && actions.length > 0"
        :show-menu-activator="hover"
        :item="{ actions }"
        :left="left"
        @menu-activator-click="$emit('menuActivatorClick', $event)"
      >
        <template #prependItems>
          <slot name="optionsMenuPrependItems" />
        </template>
      </OptionsMenu>

      <div
        v-if="actions && actions.some(action => action.active)"
        class="d-flex align-center g-1 ml-1"
      >
        <template v-for="activeAction in actions.filter(action => action.active)">
          <deck-chip
            v-if="Boolean(activeAction.activeIcon)"
            :key="activeAction.activeIcon"
            :icon="activeAction.activeIcon"
            :text="activeAction.activeIconTooltip"
            :color="activeAction.activeIconColor"
            :tooltip-props="{
              disabled: !activeAction.activeIconTooltip,
              kind: activeAction.activeIconTooltipKind,
            }"
            class="flex-none"
            size="x-small"
          />
        </template>
      </div>
    </template>
  </v-list-item>
</template>

<script>
export default {
  name: 'ListGroupActivator',
  components: {
    OptionsMenu: defineAsyncComponent(() => import('~/components/options-menu')),
  },
  props: {
    title: { type: String, required: true },
    actions: { type: Array, default: () => [] },
    draggable: { type: Boolean, default: false },
    icon: { type: String, default: null },
    left: { type: Boolean, default: false },
    hideOptionsMenu: { type: Boolean, default: false },
    active: { type: Boolean, default: false },
    toggleThroughChevron: { type: Boolean, default: false },
    groupToggled: { type: Boolean, default: false },
  },
  emits: ['activatorClicked', 'toggleGroup', 'menuActivatorClick'],
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    classes() {
      return {
        'list-group-activator--draggable': this.draggable,
        'list-group-activator--active': this.active,
      };
    },
  },
};
</script>
<style lang="scss">
.list-group-activator {
  --list-item-activator-background-color: transparent;
  --list-item-activator-background-color-highlight: var(--z-color-subtle-shade);
  --list-item-activator-background-color-toggle: var(--z-color-accent-shade);

  display: flex;
  align-items: center;
  position: relative;
  min-height: 32px !important;
  padding: 0;
  flex-grow: 1;
  min-width: 0;

  > .list-group-activator__content {
    align-items: center;
    padding: 0 var(--z-s3);
    flex-wrap: nowrap;
    transition: 200ms ease;
  }

  .sortable-chosen:hover {
    cursor: grabbing;

    > .list-group-activator__drag-icon {
      cursor: grabbing;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  .v-list-item__content {
    width: 100%;
  }

  .v-list-item__overlay {
    background-color: var(--list-item-activator-background-color);
    border-style: none;
    content: '';
    height: 100%;
    inset: 0;
    opacity: 1 !important;
    pointer-events: none;
    position: absolute;
    transition: background-color 150ms ease;
    width: 100%;

  }

  &:hover, &:focus-within {
    .v-list-item__overlay {
      background-color: var(--list-item-activator-background-color-highlight);
    }
  }

  &.list-group-activator--active {
    .v-list-item__overlay {
      background-color: var(--list-item-activator-background-color-toggle) !important;
    }
  }
}

.list-group-activator__drag-icon {
  border-radius: 4px;
  height: 100%;
  inset: 0;
  min-width: 0 !important;
  opacity: 0;
  position: absolute !important;
  transition: 200ms ease-in-out !important;
  width: 12px;
  z-index: 1;

  &:hover {
    cursor: grab;
    background-color: rgba(0, 0, 0, 0.1);
  }

  &:active {
    cursor: grabbing;
  }
}

.list-group-activator__chevron {
  flex: 0 0 auto;
  margin-bottom: 0 !important;
  opacity: 0.5;
  transition: 200ms ease;

  // Acceptable edge-case to override deck-button defaults, or else looks weird/misaligned in this very specific case
  &.v-btn.deck-button {
    min-width: 20px !important;
    width: 20px !important;
  }
}

.list-group-activator__chevron--toggled {
  opacity: 1;
}

.list-group-activator__content-icon {
  flex: 0 0 auto;
  margin-right: var(--z-s1);
  margin-bottom: 0;
  width: 20px;
}

.list-group-activator__content-title {
  flex: unset;
  font-weight: 600;
}

.list-group-activator--draggable:hover .list-group-activator__drag-icon {
  opacity: 1;
}
</style>
